'use client';

import dynamic from 'next/dynamic';

const AlertError = dynamic(() => import('./AlertError'));

const AlertErrorDynamic = () => {
  return <AlertError />;
};

export default AlertErrorDynamic;
