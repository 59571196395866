import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/SignIn/AlertErrorDynamic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/SignIn/SignInButtonDynamic.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/ui/BulkOrderBackground/rings-background.webp");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/utils/getLocaleFormatting.tsx");
