'use client';

import dynamic from 'next/dynamic';

const SignInButton = dynamic(() => import('./SignInButtonClient'));

const SignInButtonDynamic = () => {
  return <SignInButton />;
};

export default SignInButtonDynamic;
